import { template as template_f13f0a3c007e47ec8ce3e1605f30051f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_f13f0a3c007e47ec8ce3e1605f30051f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
