import { template as template_5ea443ecfca1469792ed357d513ce87d } from "@ember/template-compiler";
const FKLabel = template_5ea443ecfca1469792ed357d513ce87d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
