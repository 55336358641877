import { template as template_639fb37b8ba84f4e81fddf3c927c5f3b } from "@ember/template-compiler";
const FKText = template_639fb37b8ba84f4e81fddf3c927c5f3b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
